<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก ตำแหน่ง ผู้อำนวยการสถานศึกษา ครั้งที่ : ' +
            period_vecprovinces.period_vecprovince_times +
            ' ปี : ' +
            period_vecprovinces.period_vecprovince_year
        "
      >
        <v-row>
          <v-col cols="12">
            <div class="text-center">
              <v-chip outlined class="ma-2" color="info">
                <v-icon left>
                  mdi-label
                </v-icon>
                ผู้สมัคคร จำนวน {{ countDataValue.countAll }} คน
              </v-chip>

              <v-chip class="ma-2" color="success" outlined>
                <v-icon left>
                  mdi-label
                </v-icon>
                ผ่านคุณสมบัติ จำนวน {{ countDataValue.countPass }} คน
              </v-chip>
              <v-chip class="ma-2" color="error" outlined>
                <v-icon left>
                  mdi-label
                </v-icon>
                ไม่ผ่านคุณสมบัติ จำนวน {{ countDataValue.countNPass }} คน
              </v-chip>
              <v-chip class="ma-2" color="warning" outlined>
                <v-icon left>
                  mdi-label
                </v-icon>
                ไม่ได้ดำเนินการ จำนวน {{ countDataValue.countNull }} คน
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
            <div>
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>

          <template v-slot:item.pt_licencedr_file="{ item }">
            <div>
              <v-btn
                @click="viewpdflicensedr(item.pt_licencedr_file)"
                small
                fab
                color="info"
              >
                <v-icon>mdi-printer</v-icon></v-btn
              >
            </div>
          </template>

          <template v-slot:item.sedirector_app_status="{ item }">
            <v-chip color="success" v-if="item.sedirector_app_status > 8">
              บันทึก
            </v-chip>
            <v-chip
              @click="
                changeStatus(
                  item.sedirector_app_year,
                  item.sedirector_app_time,
                  item.sedirector_app_idcard
                )
              "
              color="warning"
              v-else
            >
              ยังไม่ได้บันทึก
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_position="{ item }">
            <v-chip
              color="success"
              v-if="item.sedirector_app_position === 'se_director'"
            >
            รองผู้อำนวยการสถานศึกษา</v-chip
            >
            <v-chip
              color="success"
              v-else-if="item.sedirector_app_position === 'teacher'"
            >
            ครูผู้สอน</v-chip
            >
            <v-chip
              color="success"
              v-else-if="item.sedirector_app_position === 's'"
            >
            ศึกษานิเทศก์</v-chip
            >
            <v-chip
              @click="
                changeStatus(
                  item.sedirector_app_year,
                  item.sedirector_app_time,
                  item.sedirector_app_idcard
                )
              "
              color="warning"
              v-else
            >
              ยังไม่ได้บันทึก
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_discipline="{ item }">
            <v-chip
              color="warning"
              v-if="item.sedirector_app_discipline === 'ever'"
            >
              เคยถูกลงโทษทางวินัย
            </v-chip>
            <v-chip
              @click="
                changeStatus(
                  item.sedirector_app_year,
                  item.sedirector_app_time,
                  item.sedirector_app_idcard
                )
              "
              color="success"
              v-else
            >
              ไม่เคยถูกลงโทษทางวินัย
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_time="{ item }">
            {{ item.sedirector_app_time + "/" + item.sedirector_app_year }}
          </template>

          <template v-slot:item.sedirector_app_file="{ item }">
            <div
              v-if="item.sedirector_app_file && item.sedirector_app_status > 8"
            >
              <div>
                <v-icon
                  color="red"
                  large
                  v-if="item.sedirector_app_file_cc === 'cc'"
                  >mdi-information</v-icon
                >
                <v-btn
                  large
                  text
                  rounded
                  color="green"
                  @click="pdfHrvecRegisDir(item.sedirector_app_file)"
                  ><v-icon large>mdi-printer</v-icon></v-btn
                >
                <v-icon
                  v-if="
                    item.sedirector_app_pvc_approve === 'pass' ||
                      item.sedirector_app_pvc_approve === 'fail' ||
                      item.sedirector_app_pvc_approve === 'CC'
                  "
                  >mdi-checkbox-marked</v-icon
                >
                <v-icon
                  v-else
                  large
                  color="red"
                  @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                  >mdi-delete-circle</v-icon
                >
              </div>
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_file_A="{ item }">
            <div
              v-if="
                item.sedirector_app_file_A && item.sedirector_app_status > 8
              "
            >
              <v-icon
                color="red"
                large
                v-if="item.sedirector_app_file_A_CC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                @click="pdfHrvecRegisDir(item.sedirector_app_file_A)"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                v-if="
                  item.sedirector_app_pvc_approve === 'pass' ||
                    item.sedirector_app_pvc_approve === 'fail' ||
                    item.sedirector_app_pvc_approve === 'CC'
                "
                >mdi-checkbox-marked</v-icon
              >
              <v-icon
                v-else
                color="red"
                large
                @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_file_B="{ item }">
            <div
              v-if="
                item.sedirector_app_file_B && item.sedirector_app_status > 8
              "
            >
              <v-icon
                color="red"
                large
                v-if="item.sedirector_app_file_B_CC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                @click="pdfHrvecRegisDir(item.sedirector_app_file_B)"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                v-if="
                  item.sedirector_app_pvc_approve === 'pass' ||
                    item.sedirector_app_pvc_approve === 'fail' ||
                    item.sedirector_app_pvc_approve === 'CC'
                "
                >mdi-checkbox-marked</v-icon
              >
              <v-icon
                v-else
                color="red"
                large
                @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_datetime="{ item }">
            {{
              item.sedirector_app_datetime
                | moment("add", "543 years")
                | moment("D MMMM YYYY : h:mm:ss a")
            }}
          </template>

          <template v-slot:item.sedirector_app_pvc_approve="{ item }">
            <div v-if="item.sedirector_app_status > 8">
              <div v-if="item.sedirector_app_pvc_approve === 'pass'">
                <v-chip rounded text color="green" dark
                  ><v-icon>mdi-checkbox-marked-circle</v-icon>ผ่าน</v-chip
                >
                <div class="pa-2">
                  <v-btn
                    @click="cancelPVCApprove(item.sedirector_app_idref)"
                    rounded
                    color="warning"
                    small
                  >
                    <v-icon>mdi-close</v-icon> ขอยกเลิกรายงาน</v-btn
                  >
                </div>
              </div>

              <div v-else-if="item.sedirector_app_pvc_approve === 'fail'">
                <v-chip rounded text color="red" dark
                  ><v-icon>mdi-close-circle-outline</v-icon>ไม่ผ่าน</v-chip
                >
              </div>

              <div v-else-if="item.sedirector_app_pvc_approve === 'CC'">
                <v-chip rounded text color="red" dark
                  ><v-icon>mdi-close-circle-outline</v-icon>ขอยกเลิก</v-chip
                >
              </div>

              <div v-else>
                <v-btn
                  @click="
                    sedirector_app_pvc_approves(item.sedirector_app_idref)
                  "
                  rounded
                  icon
                  color="grey"
                  large
                  ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
                >
                <br />
                <v-chip outlined color="warning"
                  ><v-icon color="warning">mdi-alert</v-icon>
                  กรุณาตรวจสอบก่อนปิดระบบ</v-chip
                >
              </div>
            </div>
            <div v-else>
              <v-icon color="warning">mdi-alert</v-icon> ยังไม่ได้บันทึก
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>

        <div>
          <h3 class="red--text">
            * ตรวจสอบคุณสมบัติเมื่อผู้สมัครบันทึกรายการสมบูรณ์
          </h3>
        </div>
      </base-material-card>

      <!-- V-model CancelFilePDFDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="CancelFilePDFDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.user_firstname }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h4>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="CancelFilePDFDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CandelFilePDFSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model approvepvcDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="approvepvcDrdialog" persistent max-width="90%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ตรวจสอบเอกสาร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="approvepvcDrdialogform" lazy-validation>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-img
                        v-if="sedirector_apps_id_ref.sedirector_app_image"
                        :src="
                          '/HrvecRegisDir/' +
                            sedirector_apps_id_ref.sedirector_app_image
                        "
                        height="100"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        src="https://cdn.vectorstock.com/i/preview-1x/17/61/male-avatar-profile-picture-vector-10211761.jpg"
                        height="100"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="4" class="text-center">
                      ID :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}</v-col
                    >
                    <v-col cols="12" md="4" class="text-center">
                      {{ sedirector_apps_id_ref.title_s
                      }}{{ sedirector_apps_id_ref.frist_name }}
                      {{ sedirector_apps_id_ref.last_name }}</v-col
                    >
                    <v-col cols="12" md="4" class="text-center">
                      {{ sedirector_apps_id_ref.college_code }} :
                      {{ sedirector_apps_id_ref.user_firstname }}</v-col
                    >
                    <v-col cols="12" md="3" class="text-center">
                      เลขที่ตำแหน่ง :
                      {{ sedirector_apps_id_ref.id_position }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      เงินเดือน :
                      {{ sedirector_apps_id_ref.salary_s }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      วิทยฐานะ :
                      {{ sedirector_apps_id_ref.rang_name }}
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                      อันดับ :
                      {{ sedirector_apps_id_ref.rang_level }}
                    </v-col>

                    <v-col cols="12" md="12" class="text-center">
                      <hr />
                    </v-col>

                    <v-col cols="12" md="6" class="text-center">
                      สาขาวิชาเอก :
                      {{ sedirector_apps_id_ref.ed_abb }} :
                      {{ sedirector_apps_id_ref.ed_name }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      วันเดือนปีเกิด :
                      {{
                        sedirector_apps_id_ref.brith_day +
                          "/" +
                          sedirector_apps_id_ref.brith_month +
                          "/" +
                          sedirector_apps_id_ref.brith_year
                      }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      วันเดือนปีบรรจุ :
                      {{
                        sedirector_apps_id_ref.appoin_day +
                          "/" +
                          sedirector_apps_id_ref.appoin_month +
                          "/" +
                          sedirector_apps_id_ref.appoin_year
                      }}
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                      <hr />
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      class="text-center font-weight-bold pa-5"
                    >
                      ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      เลขที่ : {{ sedirector_apps_id_ref.pt_licencedr }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      วันที่ออก :
                      {{
                        sedirector_apps_id_ref.pt_ondatedr
                          | moment("add", "543 year")
                          | moment("D MMMM YYYY")
                      }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      วันที่หมด :
                      {{
                        sedirector_apps_id_ref.pt_enddatedr
                          | moment("add", "543 year")
                          | moment("D MMMM YYYY")
                      }}
                    </v-col>

                    <v-col cols="12" md="3" class="text-center">
                      <v-btn
                        @click="
                          viewpdflicensedr(
                            sedirector_apps_id_ref.pt_licencedr_file
                          )
                        "
                        small
                        fab
                        color="info"
                      >
                        <v-icon>mdi-printer</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12" md="12" class="text-center">
                      <hr />
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      class="text-center font-weight-bold pa-2"
                    >
                      ตรวจสอบเอกสารผู้สมัคร
                      <v-radio-group
                      class="ml-15"
                        row
                        v-model="
                          sedirector_apps_id_ref.sedirector_app_pvc_approve
                        "
                        :rules="[v => !!v || '']"
                      >
                        <v-radio value="fail">
                          <template v-slot:label>
                            <div>
                              <strong class="red--text">ไม่ผ่าน</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="pass">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">ผ่าน</strong>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>

                      <v-text-field
                        v-if="
                          sedirector_apps_id_ref.sedirector_app_pvc_approve ===
                            'fail'
                        "
                        label="ไม่ผ่าน เนื่องจาก"
                        outlined
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="approvepvcDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" @click.stop="approvepvcSubmit()" rounded>
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันบันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfHrvecRegisDirdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicensedrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicensedrdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecLicenseDirector/' + pt_licencedr_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import sedirector_app_bar from "../../components/admin/sedirector_app_bar.vue";
import SedirectorAppBarPVC from "../../components/vecprovince/sedirectorAppBarPVC.vue";

export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      CancelFilePDFDrdialog: false,
      CancelFilePDFProvinceDrdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sedirector_apps: [],
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "สถานะ", align: "center", value: "sedirector_app_status" },
        {
          text: "ตรวจสอบคุณสมบัติ",
          align: "center",
          value: "sedirector_app_pvc_approve"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" },
        { text: "วิทยฐานะ", align: "left", value: "rang_name" },
        { text: "ใบประกอบวิชาชีพ", align: "left", value: "pt_licencedr_file" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        { text: "ครั้งที่/ปี", align: "center", value: "sedirector_app_time" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "sedirector_app_agework"
        },
        {
          text: "ตำแหน่งที่ใช้สมัคร",
          align: "center",
          value: "sedirector_app_position"
        },
        { text: "อายุงาน", align: "center", value: "sedirector_age_position" },
        {
          text: "อายุงานในตำแหน่ง",
          align: "center",
          value: "sedirector_age_position_work5"
        },
        { text: "วินัย", align: "center", value: "sedirector_app_discipline" },

        { text: "ใบสมัครและเอกสารประกอบฯ", align: "center", value: "sedirector_app_file" },      
       /*  {
          text: "ภาค ข.",
          align: "center",
          value: "sedirector_app_file_B"
        }, */

        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "sedirector_app_datetime"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      sedirector_apps_id_ref: [],
      period_vecprovinces: [],
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      users: [],
      pdfHrvecRegisDirdialog: false,
      pdffiles: "",
      approvepvcDrdialog: false,
      sedirector_app_filepvc: null,
      sedirector_app_pvc_approve: "pass",
      massege_cc: [],
      pt_licencedr_files: [],
      pdflicensedrdialog: false,
      countDataValue: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.userQuery();
    await this.period_vecprovinceQuery();
  },
  methods: {
    async viewpdflicensedr(pt_licencedr_file) {
      this.pt_licencedr_files = pt_licencedr_file;
      this.pdflicensedrdialog = true;
    },
    async approvepvcSubmit() {
      this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
      if (this.$refs.approvepvcDrdialogform.validate()) {
        let resultup = await this.$http.post(
          "sedirector_app.update.php",
          this.sedirector_apps_id_ref
        );

        if (resultup.data.status == true) {
          Swal.fire({
            title: "ดำเนินการข้อมูลเรียบร้อย",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          });
          await this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.approvepvcDrdialog = false;
      }
    },
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;
      this.pdfHrvecRegisDirdialog = true;
    },

    async userQuery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        provice: "check",
        ApiKey: "HRvec2021"
      });
      this.users = result.data;
    },

    async CancelFilePDF(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.CancelFilePDFDrdialog = true;
    },
    async CancelFilePDFProvinceDrdialogSubmit() {
      if (this.$refs.CancelFilePDFProvinceDrdialogform.validate()) {
        let result;
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.sedirector_apps_id_ref.tpvecprovince_scoreFile
        });
        result = await this.$http.post(
          "sedirector_app.update.vp.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFProvinceDrdialog = false;
      }
    },
    async sedirector_app_pvc_approves(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.sedirector_app_filepvc = null;
      this.approvepvcDrdialog = true;
    },

    async cancelPVCApprove(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text = this.sedirector_apps_id_ref.fristnames;
      Swal.fire({
        title: "คุณต้องการขอยกเลิกการตรวจสอบคุณสมบัติ ?",
        text: text,
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ขอยกเลิก",
        cancelButtonText: "ปิด",
        preConfirm: async masseges => {
          let massege = String(masseges);
          if (massege.length > 1) {
            this.massege_cc = massege;
          } else {
            Swal.showValidationMessage(`กรุณาป้อนเหตุผล`);
          }
        }
      }).then(async result => {
        if (result.isConfirmed) {
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_pvc_approve = "CC";
          this.sedirector_apps_id_ref.sedirector_app_pvc_approve_detail = this.massege_cc;

          let result = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async CandelFilePDFSubmit(sedirector_app_idref) {
      if (this.$refs.CancelFilePDFDrdialogform.validate()) {
        let result;
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        this.sedirector_apps_id_ref.sedirector_app_status = 7;

        result = await this.$http.post(
          "sedirector_app.update.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFDrdialog = false;
      }
    },

    async period_vecprovinceQuery() {
      let result_period_vecprovince;
      result_period_vecprovince = await this.$http.post(
        "period_vecprovince.php",
        {
          ApiKey: this.ApiKey,
          period_vecprovinc_id: "401"
        }
      );
      this.period_vecprovinces = result_period_vecprovince.data;
      let data = this.period_vecprovinces.period_vecprovince_enable;
      if (data != 1) {
        Swal.fire({
          icon: "error",
          title: "ระบบไม่เปิดใช้งาน",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/vecprovince");
          }
        });
      } else {
        await this.sedirector_appQueryAll();
      }
    },

    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype:3,
          sedirector_app_time: this.period_vecprovinces
            .period_vecprovince_times,
          sedirector_app_year: this.period_vecprovinces.period_vecprovince_year,
          province_ID: this.users.user_province_ID
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
      let data = this.sedirector_apps;
      let countAll = 0;
      let countPass = 0;
      let countNPass = 0;
      let countNull = 0;
      data.forEach(value => {
        countAll += 1;
        if (value.sedirector_app_pvc_approve == "pass") {
          countPass += 1;
        } else if (value.sedirector_app_pvc_approve == "fail") {
          countNPass += 1;
        } else {
          countNull += 1;
        }
      });
      this.countDataValue.countAll = countAll;
      this.countDataValue.countPass = countPass;
      this.countDataValue.countNPass = countNPass;
      this.countDataValue.countNull = countNull;
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_vecprovince_years() {
      let yyyy = this.period_vecprovinces.period_vecprovince_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { sedirector_app_bar, SedirectorAppBarPVC }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
